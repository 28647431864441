// Search Page
// -----------------------

// Flexsearch search example
// Taken from "Implementing Search in Your Web UI" on the gatsby-plugin-flexsearch page:
// https://www.gatsbyjs.org/packages/gatsby-plugin-flexsearch/?=flexsearch#implementing-search-in-your-web-ui

import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
// import EpisodesList from '../../components/episodes-list';
import Img from 'gatsby-image';
import SEO from '../../components/seo/seo';
var FlexSearch = require('flexsearch'); // taken from gatsby-plugin-flexsearch/gatsby-browser.js
const utils = require('../../utilities/utils');

// Search component
class SearchPage extends Component {
  state = {
    query: '',
    results: [],
  };

  /**
   * Load Flexsearch index and initialize Flexsearch.
   * Code taken from gatsby-plugin-flexsearch/gatsby-browser.js.
   */
  componentDidMount() {
    fetch('/flexsearch_index.json')
      .then(function (response) {
        return response.json();
      })
      .then(function (index) {
        Object.keys(index).forEach((lng) => {
          Object.keys(index[lng].index).forEach((idx) => {
            var index_ = index[lng].index[idx];
            var indexObj = new FlexSearch(index_.attrs);
            indexObj.import(index_.values);
            index_.values = indexObj;
          });
        });
        window.__FLEXSEARCH__ = index;
      })
      .catch(function (e) {
        return console.log('Failed fetch search index');
      });
  }

  // UNTOUCHED
  // return this.state.results.map((page, i) => (
  //   <div className="item-search" key={i}>
  //     <Link
  //       to={`/podcasts/${page.showId}/${utils.createEpisodeFoldername(
  //         page.title
  //       )}`}
  //       className="link"
  //     >
  //       <h4>{page.title} | {page.date.substring(0, page.date.indexOf('T'))}</h4>
  //     </Link>
  //   </div>
  // ));

  render() {
    const showsMeta = this.props.data.allShowsMetaJson.edges;
    // console.log(JSON.stringify(showsMeta));

    function getShowImg(showId) {
      const show = showsMeta.find((show) => show.node.id === showId);
      // console.log(show.node.logo.childImageSharp.fluid);
      return show.node.logo.childImageSharp.fixed;
    }

    function getShowName(showId) {
      const show = showsMeta.find((show) => show.node.id === showId);
      return show.node.title;
    }

    // Legend (i used single-character keys to make flexsearch_index.json smaller)
    // page.d = episode pubDate
    // page.i = show ID
    // page.t = episode title

    const ResultList = () => {
      if (this.state.results.length > 0) {
        return (
          <ul className="episodes">
            {this.state.results.map((page, i) => (
              <li key={i} className="episodes__item episodes__item--logo-info">
                <Img
                  fixed={getShowImg(page.i)}
                  alt=""
                  className="show__img show__img--search"
                />
                <div className="episodes__info">
                  <p className="search__date-show">
                    <time className="episodes__date" dateTime={page.d}>
                      {page.d.substring(0, page.d.indexOf('T'))}
                    </time>
                    <span className="episodes__show">
                      <cite>{getShowName(page.i)}</cite>
                    </span>
                  </p>
                  <Link
                    to={`/podcasts/${page.i}/${utils.createEpisodeFoldername(
                      page.t
                    )}`}
                    className="episodes__link"
                  >
                    {page.t}
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        );
      } else if (this.state.query.length > 4) {
        return 'There are no results for ' + this.state.query + '.';
      } else if (
        this.state.results.length === 0 &&
        this.state.query.length > 0
      ) {
        return 'Please enter at least 4 characters, including spaces.';
      } else {
        return '';
      }
    };

    return (
      <>
        <SEO title="Search episodes" />
        <header className="search wrapMedium">
          <h1 className="headingPage">Search Episodes</h1>
          <p className="search__intro">
            Search across the many thousands of episodes. If you’d like to see
            the episodes for a particular show, please head over to the{' '}
            <Link to="/podcasts/">Podcasts</Link> page and select the podcast
            you’re interested in.
          </p>
          <form className="search__form" role="search">
            <p className="visuallyHidden">
              Search results will appear below the form as you type.
            </p>
            <label htmlFor="searchbox">Enter a search term:</label>
            <input
              id="searchbox"
              type="search"
              onChange={this.search}
              placeholder={'Search'}
              className="search__input"
              aria-controls="search-results"
            />
          </form>
        </header>

        <h2 className="visuallyHidden">Search Results</h2>
        <div
          className="wrapMedium"
          id="search-results"
          aria-live="polite"
          role="status"
        >
          <ResultList />
        </div>
      </>
    );
  }

  getSearchResults(query) {
    var index = window.__FLEXSEARCH__.en.index;
    var store = window.__FLEXSEARCH__.en.store;
    if (!query || !index) {
      return [];
    } else {
      var results = [];
      Object.keys(index).forEach((idx) => {
        results.push(...index[idx].values.search(query));
      });

      results = Array.from(new Set(results));

      var nodes = store
        .filter((node) => (results.includes(node.id) ? node : null))
        .map((node) => node.node);

      return nodes;
    }
  }

  search = (event) => {
    const query = event.target.value;
    if (this.state.query.length > 2) {
      const results = this.getSearchResults(query);
      this.setState({ results: results, query: query });
    } else {
      this.setState({ results: [], query: query });
    }
  };
}

export default SearchPage;

export const searchPageQuery = graphql`
  query searchQuery {
    allShowsMetaJson {
      edges {
        node {
          id
          title
          logo {
            childImageSharp {
              fixed(width: 76, height: 76) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`;
